import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)">
        <g transform="translate(11, 31)">
          <path
            d="M 32.452 27.646 L 28.333 27.646 L 20.8 0 L 24.399 0 L 28.778 16.959 Q 29.131 18.313 29.446 19.714 A 162.431 162.431 0 0 1 30.059 22.553 Q 30.355 23.991 30.615 25.513 L 30.188 25.513 A 133.085 133.085 0 0 1 30.764 22.553 Q 31.061 21.115 31.395 19.714 Q 31.729 18.313 32.081 16.959 L 36.553 0 L 40.561 0 L 44.995 16.959 Q 45.366 18.313 45.691 19.714 A 246 246 0 0 1 46.331 22.553 Q 46.646 23.991 46.925 25.513 L 46.442 25.513 A 133.085 133.085 0 0 1 47.018 22.553 Q 47.314 21.115 47.648 19.714 A 367.736 367.736 0 0 1 48.316 16.959 L 52.677 0 L 56.313 0 L 48.743 27.646 L 44.643 27.646 L 39.893 10.131 A 60.708 60.708 0 0 1 39.438 8.347 A 76.163 76.163 0 0 1 39.002 6.411 Q 38.635 4.681 38.282 2.491 A 119.275 119.275 0 0 1 38.167 1.763 L 38.909 1.763 A 162.114 162.114 0 0 1 38.531 3.977 Q 38.318 5.167 38.111 6.207 A 57.144 57.144 0 0 1 37.184 10.131 L 32.452 27.646 Z M 0 19.742 L 0 18.258 L 3.525 18.258 L 3.525 19.742 A 7.836 7.836 0 0 0 3.659 21.236 Q 3.824 22.088 4.196 22.751 A 3.98 3.98 0 0 0 4.824 23.592 A 4.349 4.349 0 0 0 7.394 24.86 A 6.097 6.097 0 0 0 8.257 24.919 A 5.475 5.475 0 0 0 9.813 24.708 A 4.238 4.238 0 0 0 11.671 23.592 A 4.251 4.251 0 0 0 12.684 21.845 Q 12.889 21.184 12.947 20.387 A 8.931 8.931 0 0 0 12.97 19.742 L 12.97 0 L 16.495 0 L 16.495 19.742 A 11.024 11.024 0 0 1 16.237 22.205 Q 15.837 23.948 14.821 25.202 A 6.684 6.684 0 0 1 14.204 25.865 A 7.815 7.815 0 0 1 9.999 27.883 A 10.867 10.867 0 0 1 8.257 28.018 A 10.138 10.138 0 0 1 5.559 27.676 A 7.675 7.675 0 0 1 2.301 25.865 Q 0.052 23.761 0.001 19.92 A 13.495 13.495 0 0 1 0 19.742 Z"
            // d="M45.691667,45.15 C48.591667,46.1 50.691667,48.95 50.691667,52.2 C50.691667,57.95 46.691667,61 40.291667,61 L28.541667,61 L28.541667,30.3 L39.291667,30.3 C45.691667,30.3 49.691667,33.15 49.691667,38.65 C49.691667,41.95 47.941667,44.35 45.691667,45.15 Z M33.591667,43.2 L39.241667,43.2 C42.791667,43.2 44.691667,41.85 44.691667,38.95 C44.691667,36.05 42.791667,34.8 39.241667,34.8 L33.591667,34.8 L33.591667,43.2 Z M33.591667,47.5 L33.591667,56.5 L40.191667,56.5 C43.691667,56.5 45.591667,54.75 45.591667,52 C45.591667,49.2 43.691667,47.5 40.191667,47.5 L33.591667,47.5 Z"
            fill="currentColor"
          />
        </g>
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
